
//获取当前页面参数信息
export const getParams = () => {
    var str = window.location.href; //获取地址栏的url
    if(str.indexOf("?") <= 0){
        return {}
    }
    var num = str.indexOf("?");
    var obj = {};
    let arr = str.substr(num + 1).split("&"); //各个参数放到数组里
    if (arr.length >= 1) {
        arr.map(val => {
            obj[val.split('=')[0]] = decodeURI(val.split('=')[1]) || ''
        })
    }
    return obj
}

//匹配关键字高亮显示 params(文本,关键字)
export const keywordscolorful = (str, val) => {
    let replaceReg = new RegExp(val, "g"); // 匹配关键字正则
    let replaceString ='<span class="highlights-text">' + val + "</span>"; // 高亮替换v-html值
    return str.replace(replaceReg, replaceString); // 开始替换
};

//日期格式化函数 new Date().format("yyyy-MM-dd")
Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1,                 //月份 
        "d+": this.getDate(),                    //日 
        "h+": this.getHours(),                   //小时 
        "m+": this.getMinutes(),                 //分 
        "s+": this.getSeconds(),                 //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds()             //毫秒 
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}

//判断是否在微信浏览器
export const is_wexin = () =>{  
    var ua = navigator.userAgent.toLowerCase();  
    if(ua.match(/MicroMessenger/i)=="micromessenger") {  
        return true;  
    } else {  
        return false;  
    }  
} 

export const getDevice = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid)
      return 'android';
    if (isiOS)
      return 'ios';
  }