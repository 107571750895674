<template>
    <van-nav-bar :class="classObj" fixed :border="false" :title="title" :left-arrow="leftArrow"  @click-left="onClickLeft" @click-right="onClickRight" style="box-shadow: 0px -2px 5px;z-index:2">
        <template #left>
            <van-icon v-if="leftArrow" :name="iconName" size="20" :color="color" />
        </template>
        <template #title>
            <slot name="title">
                <span :style="{color: color}">{{title}}</span>
            </slot>
        </template>
        <template #right>
            <slot name="arrow-right">
                <span :style="{color: rightColor}">{{rightText}}</span>
            </slot>
        </template>
    </van-nav-bar>
</template>

<script>
    import { NavBar, Icon } from 'vant'
    export default {
        name: "NavBar",
        components: {
            [NavBar.name]: NavBar,
            [Icon.name]: Icon
        },
        props: {
            title: { // 标题
                type: String,
                default: ''
            },
            leftArrow: { // 是否显示左侧返回
                type: Boolean,
                default: true
            },
            plain: { // 透明度
                type: Boolean,
                default: false
            },
            color: { // 左侧图标的颜色
                type: String,
                default: '#2E2E2E'
            },
            rightText: {
                type: String,
                default: ''
            },
            rightColor: {
                type: String,
                default: '#969696'
            },
            clickRight: {
                type: Function,
                default: () => {}
            },
            hasLeftClick: { // 是否自定义返回事件
                type: Boolean,
                default: false
            },
            clickLeft: {
                type: Function,
                default: () => {}
            },
            iconName: {
                type: String,
                default: 'arrow-left'
            }
        },
        computed: {
            classObj () {
                return {
                    'opacity': this.plain
                }
            }
        },
        methods: {
            // 点击左侧按钮
            onClickLeft () {
                if(this.hasLeftClick) {
                    this.clickLeft()
                } else {
                    this.$router.go(-1)
                }
            },
            // 点击右侧按钮
            onClickRight () {
                this.clickRight()
            }
        }
    }
</script>

<style scoped>
    .van-nav-bar.opacity {
        background-color: rgba(255, 255, 255, 0) !important;
        
    }
    .van-nav-bar >>> .van-nav-bar__title {
        font-size: 18px;
        font-weight: 500;
    }
</style>