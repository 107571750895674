<template>
  <div class="queryEPC">
    <div class="wrapper">
      <nav-bar
        title="EPC配件查询"
        :plain="scrollTop <= 0"
        :color="(scrollTop > 0 && '#000') || '#fff'"
      ></nav-bar>
      <div class="search">
        <div>当前VIN码： {{ vin }}</div>
        <!-- <van-search
          v-model="vin"
          placeholder="请输入17位车架号"
          shape
          readonly
          maxlength="17"
          :clearable="false"
        >
          <template #left-icon>
            <div style="visibility: hidden; width: 0"></div>
          </template>
          <template #right-icon>
            <div class="flex">
              <div class="icon" @click="scan"><van-icon name="scan" /></div>
              <span class="line"></span>
              <div class="icon" @click="search"><van-icon name="search" /></div>
            </div>
          </template>
        </van-search> -->
      </div>
      <div class="now" v-show="choiceCar && choiceCar.modelName">
        <div class="card">
          <div class="car flex">
            <img :src="choiceCar && choiceCar.carLogoUrl" alt="" />
            <div>{{ choiceCar && choiceCar.modelName }}</div>
          </div>
          <div class="clear" @click="clearNow">清空内容</div>
        </div>
      </div>
    </div>
    <div class="mark"></div>
    <div class="config">
      <div class="title">车辆配置信息</div>
      <!-- <div class="flex">
        <div>车型</div>
        <div>{{ carConfig && carConfig.modelName }}</div>
      </div>
      <div class="flex">
        <div>内饰面料</div>
        <div>{{ carConfig && carConfig.interiorFabric }}</div>
      </div>
      <div class="flex">
        <div>底盘号</div>
        <div>{{ carConfig && carConfig.chassisNumber }}</div>
      </div>
      <div class="flex">
        <div>发动机</div>
        <div>{{ carConfig && carConfig.engine }}</div>
      </div>
      <div v-show="open">
        <div class="flex">
        <div>内部环境</div>
        <div>{{ carConfig && carConfig.internalEnvironment }}</div>
      </div>
      <div class="flex">
        <div>排放</div>
        <div>{{ carConfig && carConfig.discharge }}</div>
      </div>
      <div class="flex">
        <div>变速器</div>
        <div>{{ carConfig && carConfig.transmission }}</div>
      </div>
      <div class="flex">
        <div>空调系统</div>
        <div>{{ carConfig && carConfig.airConditioningSystem }}</div>
      </div>
      <div class="flex">
        <div>车身油漆</div>
        <div>{{ carConfig && carConfig.bodyPaint }}</div>
      </div>
      <div class="flex">
        <div>驱动器</div>
        <div>{{ carConfig && carConfig.driver }}</div>
      </div>
      <div class="flex">
        <div>生产日期</div>
        <div>{{ carConfig && carConfig.productionDate }}</div>
      </div>
      </div> -->
      <div v-for="(item,index) in carConfig" :key="index">
        <div class="flex" v-for="(val,key,i) in item" :key="i" v-show="index < 8 || open">
          <div>{{key}}</div>
          <div>{{val}}</div>
        </div>
      </div>
      <div class="open" @click="open = !open">
        <img src="@/assets/open.png" alt="" />
      </div>
    </div>
    <div class="mark"></div>
    <div class="part">
      <div class="title flex">
        <div>零部件查询</div>
        <div @click="backPrev" v-show="pageNo > 0" class="backPrev">
          <img src="@/assets/back.png" style="width: 20px" />
          返回上一级
        </div>
      </div>
      <van-search
        ref="searchInput"
        v-model="partName"
        placeholder="请输入配件名称进行搜索"
        shape
        maxlength="15"
        :clearable="false"
      >
        <template #left-icon>
          <div style="visibility: hidden; width: 0"></div>
        </template>
        <template #right-icon>
          <div class="flex">
            <div class="icon" @click="picSearch">
              <van-icon name="search" />
            </div>
          </div>
        </template>
      </van-search>
      <div v-show="pageNo == 0">
        <van-grid
          :column-num="3"
          :border="false"
          class="gridPic"
          v-if="firstPic.length > 0"
        >
          <van-grid-item
            v-for="(item, index) in firstPic"
            @click="choicePicGroup(0, item, index)"
            :key="index"
            class="van-hairline--surround"
          >
            <van-image :src="item.img" />
            <div class="text">{{ item.label }}</div>
          </van-grid-item>
        </van-grid>
        <div v-else class="noData">未查询到对应数据</div>
      </div>
      <div v-show="pageNo == 1">
        <van-grid
          :column-num="3"
          :border="false"
          class="gridPic"
          v-if="secondPic.length > 0"
        >
          <van-grid-item
            v-for="(item, index) in secondPic"
            @click="choicePicGroup(1, item, index)"
            :key="index"
            class="van-hairline--surround"
          >
            <van-image :src="item.imageLarge" />
            <div class="text">{{ item.name }}</div>
          </van-grid-item>
        </van-grid>
        <div v-else class="noData">未查询到对应数据</div>
      </div>
      <div v-show="pageNo >= 2">
        <van-grid
          :column-num="3"
          :border="false"
          class="gridPic"
          v-if="thirdPic.length > 0"
        >
          <van-grid-item
            v-for="(item, index) in thirdPic"
            @click="choicePicGroup(2, item)"
            :key="index"
            class="van-hairline--surround"
          >
            <van-image :src="item.imageLarge" />
            <div class="text">{{ item.name }}</div>
          </van-grid-item>
        </van-grid>
        <div v-else class="noData">未查询到对应数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import {
  queryVinForEpc,
  getLlqCarInfo,
  getLlqGroup,
  getLlqStructure,
  getLlqPart,
  getEpcHistorySelect,
} from "@/api";
import { Search, Icon, Grid, GridItem, Image, Toast, Dialog } from "vant";
import { getDevice } from "@/utils/util";
export default {
  name: "queryEPC",
  components: {
    NavBar,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Image.name]: Image,
  },
  data() {
    return {
      choiceCar: {}, //VIN查询结果
      vin: "SALSN2D49DA798820", //vin码
      carConfig: {}, //车辆配置信息
      firstPic: [], //主图组
      selectFirst: {}, //主图组选中
      secondPic: [], //二级图组
      selectSecond: {}, //二级图组选中
      thirdPic: [], //三级图组
      partName: "", //零部件或名称
      pageNo: 0, // 页码
      scrollTop: 0, //页面滚动高度
      choiceFirst: {}, //选择的一级
      choiceSecond: {}, //选择的二级
      open: false,
      clientHeight: "", //页面初始高度
    };
  },
  methods: {
    scan() {},
    //点击图组
    choicePicGroup(index, item, key) {
      this.partName = "";
      this.pageNo = index + 1;
      if (this.pageNo == 1) {
        this.selectFirst = item;
        this.getSecondGroup(item, key);
      } else if (this.pageNo == 2) {
        this.selectSecond = item;
        this.getThirdGroup(item, key);
      } else {
        this.selectThird = item;
        this.viewPic(item);
      }
    },
    //查询二级图组
    getSecondGroup(item, index) {
      this.choiceFirst = { ...item };
      getLlqStructure({
        ...item,
        vin: this.vin,
        queryName: this.partName,
      }).then((res) => {
        if (res.data.data.code == 1) {
          this.secondPic = res.data.data.data;
        }
      });
    },
    //查询三级图组
    getThirdGroup(item, index) {
      this.choiceSecond = { ...item };
      console.log(item);
      getLlqStructure({
        ...item,
        vin: this.vin,
        modelCode: this.choiceCar.carModelCode,
        name: `${this.selectFirst.label}/${item.name}`,
        queryName: this.partName,
      }).then((result) => {
        if (result && result.data.data.code == 1)
          this.thirdPic = result.data.data.data;
        console.log(this.secondPic);
      });
    },
    //查看最后大图
    viewPic(item) {
      getLlqPart({
        ...item,
        modelCode: this.choiceCar.carModelCode,
        vin: this.vin,
      }).then((res) => {
        sessionStorage.setItem("partDetail", JSON.stringify(res.data.data));
        sessionStorage.setItem("brandCode", item.brandCode);
        this.$router.push({ name: "partList" });
      });
    },
    picSearch() {
      var { vin, partName, choiceFirst, choiceSecond } = this;
      switch (this.pageNo) {
        case 0:
          getLlqGroup({ vin, queryName: partName }).then((res) => {
            console.log(res);
            if (res.data.data && res.data.data.code == 1) {
              this.firstPic = res.data.data.data;
            }
          });
          break;
        case 1:
          this.getSecondGroup(choiceFirst);
          break;
        case 2:
          this.getThirdGroup(choiceSecond);
          break;
        default:
          break;
      }
    },
    //点击VIN查询
    search() {
      this.queryName = "";
      this.pageNo = 0;
      var { vin } = this;
      //按VIN码查询
      queryVinForEpc({ vin, userId: sessionStorage.getItem("userId") })
        .then((res) => {
          console.log(res.data.data);
          if (res && res.data.data.flag == "ok") {
            this.choiceCar = res.data.data;
            return this.choiceCar.carModelCode;
          }
        })
        .then((code) => {
          //查配置信息
          getLlqCarInfo({ vin, brandCode: code }).then((res) => {
            if (res) {
              console.log(res);
              this.carConfig = res.data.data;
            }
          });
        })
        .then(() => {
          //查主图组
          getLlqGroup({ vin }).then((res) => {
            console.log(res);
            if (res.data.data && res.data.data.code == 1) {
              this.firstPic = res.data.data.data;
            }
          });
        });
    },
    clearNow() {
      this.pageNo = 0;
      this.$router.go(-1);
    },
    backPrev() {
      this.partName = "";
      this.pageNo -= 1;
    },
    watchKeyBoard() {
      console.log(this.clientHeight, "初始高度");
      window.addEventListener(
        "resize",
        () => {
          this.$refs["searchInput"].scrollIntoView();
        },
        false
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("resize", () => {
      this.$refs["searchInput"].scrollIntoView();
    });
    next();
  },
  mounted() {
    window.onscroll = () => {
      this.scrollTop = document.documentElement.scrollTop;
    };
    this.clientHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    this.watchKeyBoard();
    console.log(this.$route.params.vin);
    if (this.$route.params.vin) {
      this.vin = this.$route.params.vin;
      this.search();
    }
  },
};
</script>

<style lang="less" scoped>
.queryEPC {
  .noData {
    margin: 80px auto;
    text-align: center;
    color: #ccc;
  }
  .part {
    padding: 17px 20px;
    height: 313px;
    overflow-y: scroll;
    background: #fff;
    .gridPic {
      margin-top: 5px;
      /deep/.van-grid-item__content {
        height: unset;
        padding: 0 6px;
      }
      img {
        width: 80px;
        height: 80px;
      }
      .text {
        text-align: center;
        font-size: 12px;
      }
    }
    .title {
      color: #161616;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .van-search {
      padding: 0;
    }
    .backPrev {
      color: #969696;
      font-size: 12px;
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
  }
  .mark {
    height: 10px;
    background: #f8f8f8;
  }
  .config {
    padding: 17px 20px;
    // height: 313px;
    // overflow-y: scroll;
    background: #fff;
    .open {
      display: flex;
      justify-content: center;
      img {
        width: 40px;
      }
    }
    .title {
      color: #161616;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .flex {
      div {
        margin: 5px 0;
        color: #464646;
        font-size: 12px;
        &:first-child {
          color: #484848;
          width: 80px;
        }
        &:last-child {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
  .wrapper {
    background: linear-gradient(to right, #fbde98, #f6bc50);
    .search {
      div {
        padding: 10px 15px 0;
        color: #fff;
        font-size: 16px;
      }
      .van-search {
        background: linear-gradient(to right, #fbde98, #f6bc50);
      }
      padding-top: 50px;
      .flex {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .line {
          height: 20px;
          margin: 0 10px;
          width: 1px;
          background: #ccc;
        }
        .icon {
          width: 30px;
          height: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
    .now {
      padding: 10px 15px;
      .card {
        background: linear-gradient(to right, #fae4b0, #f7ce7f);
        border-radius: 5px;
        .car {
          padding: 12px 17px;
          word-break: break-all;
          justify-content: unset;
          color: #785410;
          img {
            margin-right: 10px;
            width: 33px;
            height: 33px;
            border-radius: 50%;
          }
        }
        .clear {
          color: #ca932b;
          height: 37px;
          line-height: 37px;
          text-align: center;
          border-top: 1px dashed #ca932b;
        }
      }
    }
  }
}
</style>